import { ArkSearchBarFilter } from '@ark';
import { TeamSeasonStatus } from '@index/interfaces/team-season';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GthTeamModel } from '@sentinels/models';
import { TeamsState } from '@sentinels/state/features/teams/state';
import { CurrentState } from '@sentinels/state/state';
import { distanceBetween } from 'geofire-common';

import { selectAuthState, selectUser } from '../auth/selectors';
import { generateBoundsFromLatLng, isLocationWithinBounds } from '../helpers/geolocation';

export const selectTeamsState = createFeatureSelector<TeamsState>('teams');

export const selectTeamsLoading = createSelector(
  selectTeamsState,
  (state) => state.state === CurrentState.Loading,
);

export const selectAllTeams = createSelector(
  selectTeamsState,
  (state) => state?.teams || [],
);

export const selectAExternalTeams = createSelector(
  selectTeamsState,
  (state) => state.externalTeams,
);

export const selectRoles = createSelector(
  selectTeamsState,
  (state) => state.roles,
);


export const selectTeamsByLatLng = (
  lat: number,
  lng: number,
  limit = 100,
  filter?: ArkSearchBarFilter,
  bounds?: google.maps.LatLngBoundsLiteral,
) => createSelector(
  selectUser,
  selectFilteredTeams(filter),
  (user, teams): { teams: GthTeamModel[], total: number } => {
    if (!user?.defaultCity || !teams) return { teams: [], total: 0 };

    bounds = bounds || generateBoundsFromLatLng(lat, lng);

    const filteredTeams = teams.filter((team) => isLocationWithinBounds(
      bounds,
      team,
      (team) => team.location.lat,
      (team) => team.location.lng));

    const sortedAndSlicedTeams = filteredTeams
      // TODO: Fix created property returning null
      // .sort((a, b)=>(b.created.nanoseconds - a.created.nanoseconds))
      .slice(0, limit);

    return { teams: sortedAndSlicedTeams, total: filteredTeams.length };
  },
);

export const selectTeamsByLatLngForAuthUser = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,
) => createSelector(
  selectUser,
  selectFilteredTeams(filter),
  (user, teams): { teams: GthTeamModel[], total: number } => {
    if (!user?.defaultCity || !teams) return { teams: [], total: 0 };

    const lat = user.defaultCity.lat;

    const lng = user.defaultCity.lng;

    const radiusInM = 200 * 1000;

    const center: [number, number] = [lat, lng];

    const filteredTeams = teams.filter((team) => {
      if (!team?.location?.lat || !team?.location?.lng) return false;

      if (!team.discoverable) return false;


      const distanceInKm = distanceBetween(
        [team.location.lat, team.location.lng],
        center,
      );

      const distanceInM = distanceInKm * 1000;

      return distanceInM <= radiusInM;
    });

    const sortedAndSlicedTeams = filteredTeams
      // TODO: Fix created property returning null
      // .sort((a, b)=>(b.created.nanoseconds - a.created.nanoseconds))
      .slice(start, end);

    return { teams: sortedAndSlicedTeams, total: filteredTeams.length };
  },
);

export const selectFilteredTeams = (filter: ArkSearchBarFilter) => createSelector(
  selectAllTeams,
  (teams) => {
    if (!teams) return [];
    if (!filter?.searchText) return teams;

    return teams
      .filter((team) =>
        filter.searchText ?
          team.name.toLowerCase().includes(filter.searchText.toLowerCase()) ||
          team.description.toLowerCase().includes(filter.searchText.toLowerCase()) :
          true,
      );
  },
);

export const selectTeamById = (id: string) => createSelector(
  selectAllTeams,
  (teams) => teams.find((team) => team.id === id),
);


export const selectTeamByUsersId = createSelector(
  selectAllTeams,
  selectAuthState,
  (teams, auth) => teams.filter((team) => {
    return team.isUserAdmin(
      auth.user,
    );
  },
  ),
);

export const selectSessionsByTeamId = (teamId: string) => createSelector(
  selectTeamsState,
  (state) => state.sessions?.get(teamId),
);

export const selectSeasonsByTeamId = (teamId: string) => createSelector(
  selectTeamsState,
  (state) => {
    const seasons = state.seasons?.get(teamId);
    if (!seasons) {
      return [];
    }
    return seasons.filter((season) => season.status !== TeamSeasonStatus.ARCHIVED);
  },
);

export const selectSeasonGroupsByTeamId = (teamId: string) => createSelector(
  selectTeamsState,
  (state) => state.seasonGroups?.get(teamId),
);
